import React, { useEffect, useState } from 'react'
import { FaRegUser } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { useCreateNewProfileMutation, usePatchCollaboratorMutation } from '../../../redux/appSlices/collaborator/collaboratorApiSlice';
import { getCollaborator } from '../../../redux/appSlices/collaborator/collaboratorSlice';
import { toast } from 'react-toastify';
import SwitchCreateProfile from '../../Modal/SwitchCreateProfile';
import { translation } from '../../../translation';

const CreateNewProfile = ({ setOpenEditPopup }) => {

    const dispatch = useDispatch()

    const collaborator = useSelector(
        (state) => state.collaborator.collaboratorInfo
    );
    const lang = useSelector(
        (state) => state.collaborator.collaboratorInfo.language
    );

    // useState
    const [openSwitchProfileModal, setOpenSwitchProfileModal] = useState(false)
    const [popupMessage, setPopupMessag] = useState(' ')


    //   API CALLS
    const [createNewProfile] = useCreateNewProfileMutation()
    const [patchCollaborator, { isLoading }] = usePatchCollaboratorMutation();

    // FUNCTIONS
    const handleCreateNewProfile = () => {
        setPopupMessag(translation.settings.switchProfile.createProfile[lang])
        setOpenSwitchProfileModal(true)
        createNewProfile({
            collaborator_id: collaborator.collaborator_id
        })
            .unwrap()
            .then((res) => {
                setTimeout(() => {
                    setOpenSwitchProfileModal(false)
                    setOpenEditPopup(false)
                }, 3000);
            })
            .catch((err) => {
                toast.error(err?.data?.message?.[lang]);
                setOpenSwitchProfileModal(false)
            })
    }

    const handleSwitchProfile = (id) => {
        console.log('id ', id)
        if ((id == null && collaborator.currentProfile === null) || (id !== null && id === collaborator?.currentProfile?._id)){
            setOpenEditPopup(false)
            return null
        }
        // => return Error with some text that Shows that you are alredy in that profile click on other profile to change it or close settings
        setPopupMessag(translation.settings.switchProfile.switchProfile[lang])
        setOpenSwitchProfileModal(true)
        patchCollaborator({
            id: collaborator._id,
            content: { currentProfile: id }
        })
            .unwrap()
            .then((res) => {
                setTimeout(() => {
                    setOpenSwitchProfileModal(false)
                    setOpenEditPopup(false)
                }, 3000);

            })
            .catch((err) => toast.error(err?.data?.message?.[lang]))
    }

    return (
        <div>
            {/* <div className={`text-2xl mt-[2rem] ${lang === 'ar' ? 'font-lalezarRegular' : 'font-SteradianMedium'}`}>
                {translation.settings.switchProfile.title[lang]}
            </div> */}

            <div className="text-[18px] mt-[2rem]">
                {translation.settings.switchProfile.subtitle[lang]}
            </div>

            {collaborator.profiles.map((profile) => (
                <div
                    key={profile?._id}
                    onClick={() => handleSwitchProfile(profile?._id === collaborator._id ? null : profile?._id)}
                    className={`flex justify-between items-center mt-3 rounded-[10px] px-3 py-2 cursor-pointer ${(!collaborator?.currentProfile && profile?._id === collaborator._id) ? 'border border-labels' : profile?._id === collaborator?.currentProfile?._id && 'border border-labels'}`}
                >
                    <div className="flex items-center">
                        <div className={`w-9 h-9 rounded-full ${!profile.profile_picture && 'border border-labels'}`}>
                            {
                                profile.profile_picture &&
                                <img src={`${process.env.REACT_APP_IMAGE_PATH}${profile?.profile_picture}`} className="object-cover h-full w-full rounded-full" />
                            } 
                        </div>
                        <div className={lang === 'ar' ? 'pr-4' : 'pl-4'}>
                            <h3 className="font-SteradianMedium text-[18px] tracking-[-.97px]">{profile?.first_name ? profile?.first_name : '-'} {' '} {profile?.last_name ? profile?.last_name : '-'}</h3>
                            <p className="text-[14px] text-labels tracking-[-.65px]">{profile?.title?.[lang] ? profile?.title?.[lang] : '-'}</p>
                        </div>
                    </div>
                    <div className={`h-4 w-4 rounded-full  ${(!collaborator?.currentProfile && profile?._id === collaborator._id) ? 'bg-labels' : profile?._id === collaborator?.currentProfile?._id ? 'bg-labels' : 'border border-labels'}`} />
                </div>
            ))}



            {
                collaborator.profiles.length < 2 &&
                <div
                    onClick={handleCreateNewProfile}
                    className="flex text-labels gap-3 border border-dashed border-labels bg-labelsBg rounded-md py-2 items-center justify-center mt-5 cursor-pointer"
                >
                    <FaRegUser className="text-lg text-labels" />
                    <span>{translation.settings.switchProfile.buttonNewProfile[lang]}</span>
                </div>
            }


            {
                openSwitchProfileModal &&
                <SwitchCreateProfile
                    isOpen={true}
                    message={popupMessage}
                />
            }
        </div>
    )
}

export default CreateNewProfile